<template>
  <PageHeader image="header-2" withMobile :title="$lang('title')" :breadcrumbs="$lang('breadcr')" />
  <!-- section1 -->
  <section class="pt-8 pb-9 bg-green2">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-5 mb-5 mb-lg-0">
          <h2 class="fw-bold text-blue1 mb-4">
            {{ $lang("section1.title") }}
          </h2>
          <p class="text-white">
            {{ $lang("section1.text") }}
          </p>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <ImageContainer fileName="careers-1" class="img-container-rounded" />
        </div>
      </div>
    </div>
  </section>

  <section class="pt-8 pb-10">
    <div class="container">
      <div>
        <div id="BambooHR" data-domain="nedcollege.bamboohr.com" data-version="1.0.0" data-departmentId=""></div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "@/components/PageHeader";

export default {
  components: {
    PageHeader,
  },
  data() {
    return {
      bambooScript: null,
    };
  },
  mounted() {
    this.loadBambooScript();
  },
  beforeUnmount() {
    this.unloadBambooScript();
  },
  methods: {
    loadBambooScript() {
      this.bambooScript = document.createElement("script");
      this.bambooScript.setAttribute("src", "/bamboo-hr.js");
      this.bambooScript.setAttribute("type", "text/javascript");
      this.bambooScript.setAttribute("async", "async");
      this.bambooScript.setAttribute("defer", "defer");
      document.head.appendChild(this.bambooScript);
    },
    unloadBambooScript() {
      if (this.bambooScript && this.bambooScript.parentNode) {
        this.bambooScript.parentNode.removeChild(this.bambooScript);
        this.bambooScript = null;
      }
    },
  },
};
</script>

<style></style>
